<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="4">
                    <ValidationProvider name="grade" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('grade')">
                            <b-form-input v-model="formData.grade"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="point" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('point')">
                            <b-form-input type="number"
                                          step="0.01"
                                          v-model="formData.point"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="score" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('score')">
                            <b-form-input type="number"
                                          v-model="formData.score"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="instructor_can_use" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('instructor_can_use')">
                            <true-false-selectbox v-model="formData.instructor_can_use"
                                                  :options="trueFalseOptions"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="is_passing" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('is_passing')">
                            <true-false-selectbox v-model="formData.is_passing"
                                                  :options="trueFalseOptions"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="effect_gpa" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('effect_gpa')">
                            <true-false-selectbox v-model="formData.effect_gpa"
                                                  :options="trueFalseOptions"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="can_be_repeated" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('can_be_repeated')">
                            <true-false-selectbox v-model="formData.can_be_repeated"
                                                  :options="trueFalseOptions"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('explanation')">
                        <div class="mb-1">
                            <ValidationProvider name="explanation" v-slot="{ valid, errors }">
                                <b-input-group prepend="TR">
                                    <b-form-input v-model="formData.explanation"
                                                  :class="errors[0] ? 'is-invalid' : ''"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </ValidationProvider>
                        </div>
                        <div>
                            <ValidationProvider name="explanation_en" v-slot="{ valid, errors }">
                                <b-input-group prepend="EN">
                                    <b-form-input v-model="formData.explanation_en"
                                                  :class="errors[0] ? 'is-invalid' : ''"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </ValidationProvider>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="program_level" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('program_level')">
                            <multi-parameter-selectbox v-model="formData.program_level"
                                                       code="program_levels"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import MultiParameterSelectbox from '@/components/interactive-fields/MultiParameterSelectbox';
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';
    import TrueFalseSelectbox from '@/components/interactive-fields/TrueFalseSelectbox';

    // Services
    import GradeService from '@/services/GradeService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            MultiParameterSelectbox,
            StatusSelectbox,
            TrueFalseSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            gradingSystemId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {
                    grade: null,
                    point: null,
                    score: null,
                    instructor_can_use: null,
                    is_passing: null,
                    effect_gpa: null,
                    can_be_repeated: null,
                    explanation: null,
                    explanation_en: null,
                    program_level: null
                },
                trueFalseOptions: [
                    {value: null, text: ''},
                    {value: 1, text: this.$t('yes')},
                    {value: 0, text: this.$t('no')}
                ]
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    let formData = {
                        grading_system_id: this.gradingSystemId,
                        ...this.formData
                    }
                    GradeService.store(formData)
                                .then((response) => {
                                    this.$emit('createFormSuccess', true);
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                })
                                .catch((e) => {
                                    this.showErrors(e, this.$refs.formModalValidate)
                                })
                                .finally(() => {
                                    this.formLoading = false
                                })
                }
            }
        }
    }
</script>

