<template>
    <div>
        <page-header :is-new-button="true"
                     :is-filter="true"
                     @new-button-click="createFormShow"
                     @filter-div-status="datatable.filterStatus=$event"
        />
        <datatable-filter v-show="datatable.filterStatus"
                          @filter="filter"
                          @filterClear="filterClear"
                          :isSmallFilter="true"
        >
            <b-row>
                <b-col cols="12">
                    <b-form-input v-model="datatable.queryParams.filter.grade" :placeholder="$t('name')"/>
                </b-col>
            </b-row>
        </datatable-filter>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   :lineNumbers="false"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
        />
        <CommonModal ref="formModal"
                     :onHideOnlyX="true"
                     size="xl"
                     @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <span v-if="formProcess=='create'">{{ $t('new').toUpper() }}</span>
                <span v-if="formProcess=='update'">{{ $t('update').toUpper() }}</span>
            </template>
            <template v-slot:CommonModalContent>
                <create-form @createFormSuccess="createFormSuccess"
                             :gradingSystemId="gradingSystemId"
                             v-if="formProcess=='create' && checkPermission('gradingsystem_store')"
                />
                <update-form :formId="formId"
                             @updateFormSuccess="updateFormSuccess"
                             v-if="formProcess=='update' && checkPermission('gradingsystem_update')"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Tempolate
    import AppLayout from '@/layouts/AppLayout.vue';
    import PageHeader from '@/components/page/PageHeader.vue'

    // Component
    import CommonModal from '@/components/elements/CommonModal.vue';
    import DatatableFilter from '@/components/datatable/DatatableFilter.vue';
    import Datatable from '@/components/datatable/Datatable.vue';

    // Pages
    import CreateForm from './CreateForm';
    import UpdateForm from './UpdateForm';

    // Services
    import GradeService from '@/services/GradeService';

    // Other
    import qs from 'qs';

    export default {
        components: {
            AppLayout,
            PageHeader,

            CommonModal,
            DatatableFilter,
            Datatable,

            CreateForm,
            UpdateForm
        },
        props: {
            gradingSystemId: {
                type: Number
            }
        },
        data() {
            return {
                formId: null,
                formProcess: null,
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: 'grade_update',
                                    callback: (row) => {
                                        this.updateFormShow(row.id);
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-2-line',
                                    permission: 'grade_delete',
                                    callback: (row) => {
                                        this.formDelete(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('grade'),
                            field: 'grade',
                            sortable: true
                        },
                        {
                            label: this.$t('score'),
                            field: 'score',
                            sortable: true
                        },
                        {
                            label: this.$t('point'),
                            field: 'point',
                            sortable: true
                        },
                        {
                            label: this.$t('instructor_can_use'),
                            field: 'instructor_can_use',
                            sortable: false,
                            formatFn:(value)=>{
                                return value == true ? this.$t('yes') : this.$t('no')
                            },
                        },
                        {
                            label: this.$t('is_passing'),
                            field: 'is_passing',
                            sortable: false,
                            formatFn:(value)=>{
                                return value == true ? this.$t('yes') : this.$t('no')
                            }
                        },
                        {
                            label: this.$t('effect_gpa'),
                            field: 'effect_gpa',
                            sortable: false,
                            formatFn:(value)=>{
                                return value == true ? this.$t('yes') : this.$t('no')
                            }
                        },
                        {
                            label: this.$t('can_be_repeated'),
                            field: 'can_be_repeated',
                            sortable: false,
                            formatFn:(value)=>{
                                return value == true ? this.$t('yes') : this.$t('no')
                            }
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'grade',
                        page: 1,
                        limit: 10
                    }
                }
            };
        },
        methods: {
            filter() {
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {};
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                return GradeService.getAll(config)
                                   .then((response) => {
                                       this.datatable.rows = response.data.data;
                                       this.datatable.total = response.data.pagination.total;
                                   })
                                   .finally(() => {
                                       this.datatable.isLoading = false;
                                   });
            },

            // Clear
            formClear() {
                this.formId = null
                this.formProcess = null
            },

            // Create
            createFormShow() {
                this.formId = null
                this.formProcess = 'create'
                this.$refs.formModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            // Update
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            // Delete
            formDelete(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            GradeService.deleteGrade(id)
                                        .then(response => {
                                            this.$toast.success(this.$t('api.' + response.data.message));
                                            this.getRows();
                                            this.formClear();
                                        })
                                        .catch(e => {
                                            this.showErrors(e)
                                        })
                        }
                    })
            }

        },
        created() {
            this.datatable.queryParams.filter.grading_system_id = this.gradingSystemId;
            this.getRows();
        }
    }
</script>

