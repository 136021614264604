<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="4">
                    <ValidationProvider name="grade" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('grade')">
                            <b-form-input v-model="formData.grade"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="point" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('point')">
                            <b-form-input type="number"
                                          step="0.01"
                                          v-model="formData.point"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="score" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('score')">
                            <b-form-input type="number"
                                          v-model="formData.score"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="instructor_can_use" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('instructor_can_use')">
                            <yes-no-selectbox v-model="formData.instructor_can_use"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="is_passing" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('is_passing')">
                            <yes-no-selectbox v-model="formData.is_passing"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="effect_gpa" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('effect_gpa')">
                            <yes-no-selectbox v-model="formData.effect_gpa"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="can_be_repeated" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('can_be_repeated')">
                            <yes-no-selectbox v-model="formData.can_be_repeated"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('explanation')">
                        <div class="mb-1">
                            <ValidationProvider name="explanation" v-slot="{ valid, errors }">
                                <b-input-group prepend="TR">
                                    <b-form-input v-model="formData.explanation"
                                                  :class="errors[0] ? 'is-invalid' : ''"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </ValidationProvider>
                        </div>
                        <div>
                            <ValidationProvider name="explanation_en" v-slot="{ valid, errors }">
                                <b-input-group prepend="EN">
                                    <b-form-input v-model="formData.explanation_en"
                                                  :class="errors[0] ? 'is-invalid' : ''"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </ValidationProvider>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="program_level" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('program_level')">
                            <multi-parameter-selectbox v-model="formData.program_level"
                                                       code="program_levels"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import MultiParameterSelectbox from '@/components/interactive-fields/MultiParameterSelectbox';
    import YesNoSelectbox from '@/components/interactive-fields/YesNoSelectbox'

    // Services
    import GradeService from '@/services/GradeService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            MultiParameterSelectbox,
            YesNoSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {
                    grade: null,
                    point: null,
                    score: null,
                    instructor_can_use: null,
                    is_passing: null,
                    effect_gpa: null,
                    can_be_repeated: null,
                    explanation: null,
                    explanation_en: null,
                    program_level: null
                },
                trueFalseOptions: [
                    {value: null, text: ''},
                    {value: 1, text: this.$t('yes')},
                    {value: 0, text: this.$t('no')}
                ]
            }
        },
        created() {
            this.get()
        },
        methods: {
            async get() {
                await GradeService.get(this.formId)
                                  .then(response => {
                                      let data = response.data.data;
                                      this.formData = {
                                          grading_system_id: data.grading_system_id,
                                          grade: data.grade,
                                          point: data.point,
                                          score: data.score,
                                          instructor_can_use: data.instructor_can_use ? 'E':'H',
                                          is_passing: data.is_passing ? 'E':'H',
                                          effect_gpa: data.effect_gpa ? 'E':'H',
                                          can_be_repeated: data.can_be_repeated ? 'E':'H',
                                          explanation: data.explanation,
                                          explanation_en: data.explanation_en,
                                          program_level: data.program_level
                                      }
                                  })
                                  .catch(e => {
                                      this.showErrors(e);
                                  })
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;

                    this.formData.instructor_can_use = this.formData.instructor_can_use == 'E' ? 1 : 0
                    this.formData.is_passing = this.formData.is_passing == 'E' ? 1 : 0
                    this.formData.effect_gpa = this.formData.effect_gpa == 'E' ? 1 : 0
                    this.formData.can_be_repeated = this.formData.can_be_repeated == 'E' ? 1 : 0

                    GradeService.update(this.formId, this.formData)
                                .then(response => {
                                    this.$emit('updateFormSuccess', true);
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                })
                                .catch(e => {
                                    this.showErrors(e, this.$refs.formModalValidate);
                                })
                                .finally(() => {
                                    this.formLoading = false
                                })
                }
            }
        }
    }
</script>
