<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           prepend="TR">
                                <b-form-input v-model="formData.name"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           prepend="EN">
                                <b-form-input v-model="formData.name_en"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('status')">
                            <status-selectbox v-model="formData.status"
                                              :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';

    // Services
    import GradingSystemService from '@/services/GradingSystemService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            StatusSelectbox,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                formLoading: false,
                formData: {
                    name: null,
                    name_en: null,
                    status: null
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    GradingSystemService.store(this.formData)
                                        .then(response => {
                                            this.$emit('createFormSuccess', true);
                                            this.$toast.success(this.$t('api.' + response.data.message));
                                        })
                                        .catch(e => {
                                            this.showErrors(e, this.$refs.formModalValidate);
                                        })
                                        .finally(() => {
                                            this.formLoading = false
                                        })
                }
            }
        }
    }
</script>

